<template>
  <div class="card card-body shadow-sm">
    <app-basic-table
      ref="basic_table"
      :table-name="$t('users.user list')"
      :filters.sync="filters"
      :setting-columns="columns"
      :endpoint-list="ENDPOINT.CUSTOMER_MEAL_LIST"
      @resetAllSearch="onResetAllSearch"
      @searched="onSearch"
    >
      <template v-slot:filters>
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-sm-4">
            <div class="form-group">
              <label>{{ filters.apartment_id_equal.label }}</label>
              <app-select :name="filters.apartment_id_equal.name"
                          input-style="normal"
                          v-model="filters.apartment_id_equal.value"
                          :options-data="meta.apartments"

              />
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-sm-4">
            <div class="form-group">
              <label>{{ filters.customer_name_like.label }}</label>
              <app-input :name="filters.customer_name_like.name" input-style="normal"
                         v-model="filters.customer_name_like.value"
              />
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-sm-4">
            <div class="form-group">
              <label>{{ filters.date_equal.label }}</label>

              <app-date-range-picker
                :name="filters.date_equal.name"
                v-model="filters.date_equal.value"
                timePicker24Hour="false"
                format="YYYY-MM-DD"
                @input="filters.date_equal.value = $event"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <label class="col-xl-1 col-lg-2 col-sm-2 title-radio col-form-label">{{ filters.type_meal_equal.label }}</label>
          <div class="col-xl-5 col-lg-9 col-sm-9 gr-icheck">
            <app-radio v-for="(item, index) in FILTER_TYPE_THREE_MEALS"
                       :isMarginLeft="index !=0 ? true : false" :key="item.id" :label="item.name"
                       :val="item.id" v-model="filters.type_meal_equal.value"/>
          </div>
        </div>
      </template>

      <template v-slot:table-menu-right>
        <button @click="handleBtnCreateClick()" class="btn btn-warning">
          {{ $t("common.create") }}
        </button>
      </template>

      <template v-slot:body-cell-apartment_id="props">
        <td class="app-align-middle">
            <p :data-original-title="getApartmentName(props.row.apartment_id)" style="color: #007bff;" @click="handlerApartmentNameClick(props.row)"  class="apartment_name app-table-p app-cell-tooltip mb-0">
              {{props.row.apartment_name}}
            </p>
        </td>
      </template>

      <template v-slot:body-cell-customer_id="props">
        <td class="app-align-middle">
          <p :data-original-title="props.row.customer_name" class="app-table-p app-cell-tooltip mb-0" >
              {{ props.row.customer_name}}
          </p>
        </td>
      </template>

      <template v-slot:body-cell-edit="props">
        <td class="app-align-middle text-center app-p-none">
          <button
            @click="handleBtnUpdateClick(props.row)"
            class="btn btn-primary"
          >
            {{ $t("common.edit") }}
          </button>
        </td>
      </template>


    </app-basic-table>

    <!--    <create-component-->
    <!--      ref="modal_create"-->
    <!--      @onSuccess="handleCreateSuccess"-->
    <!--    ></create-component>-->

    <!--    <update-component-->
    <!--      @onSuccess="handleUpdateSuccess"-->
    <!--      ref="modal_update"-->
    <!--    ></update-component>-->

  </div>
</template>

<script>
  import AppBasicTable from "@components/_common/list/AppBasicTable";
  import AppDateRangePicker from "../../../components/forms/AppDateRangepicker";
  import {ENDPOINT} from "../../../constants/api";

  export default {
    name: "CustomerMealListPage",
    components: {
      "app-basic-table": AppBasicTable,
      AppDateRangePicker,
    },
    data() {
      return {
        idCache: null,
        paramGetList: {},
        filters: {
          apartment_id_equal: {
            name: "apartment_id",
            condition: "equal",
            label: this.$t("common.mansion")
          },
          customer_name_like: {
            name: "customer_name_like",
            condition: "like",
            label: this.$t("customer-meals.customer_name_kana")
          },
          date_equal: {
            name: "date_equal",
            condition: "between",
            label: this.$t("common.date")
          },
          type_meal_equal: {
            name: "type_meal",
            condition: "equal",
            label: this.$t("customer-meals.type_of_three_meals")
          },
          default: {
            // sortBy :'served_at',
            // sortType :'desc'
          }
        },
        columns: [
          {name: "served_at", label: this.$t("common.date"), sortable: true, textAlign: 'text-center'},
          {name: "hour", label: this.$t("common.hour"), textAlign: 'text-center'},
          {name: "customer_id", label: this.$t("common.customer_name"), sortable: true},
          {name: "customer_name_kana", label: this.$t("common.name_kana"), sortable: true},
          {name: "apartment_id", label: this.$t("common.mansion"), sortable: true},
          {name: "type_meal", label: this.$t("customer-meals.type_of_three_meals"), sortable: true},
          {name: "note", label: this.$t("customer-meals.content")},
          {name: "edit", label: this.$t("common.edit"), textAlign: 'text-center'},
        ],
        meta: {
          apartments: [],
        },
        endPoint : ''
      };
    },
    methods: {
      handleBtnCreateClick() {
        localStorage.setItem('meal_search_url', window.location.href);
        this.$router.push({name: this.ROUTES.ADMIN.CUSTOMER_MEAL_CREATE})
      },
      handleBtnUpdateClick(entry) {
        localStorage.setItem('meal_search_url', window.location.href);
        this.$router.push({name: this.ROUTES.ADMIN.CUSTOMER_MEAL_EDIT, params: {type: entry.type_name, id: entry.id}})
      },
      handlerApartmentNameClick(entry) {
        this.$router.push({name: this.ROUTES.ADMIN.APARTMENT_EDIT, params: {id: entry.apartment_id}})
      },
      onResetAllSearch() {
        this.$router.push({name: this.$route.name, query: {'filters.date_equal.between' : moment().format('YYYY-MM-DD')}})
      },
      onSearch() {
        // this.getTourSelectName();
      },
      getApartmentName(apartmentId)
      {
        let apartment = this.meta.apartments.find(({id}) => id === apartmentId);
        if (apartment == undefined) {
          return '';
        }
        return apartment.name;
      },
    },
    mounted: function () {
      this.endPoint = ENDPOINT.CUSTOMER_MEAL_LIST + '?sortBy=served_at&sortType=desc'
      this.$request.get(this.ENDPOINT.UI_HELPER_CUSTOMER_MEALS).then(res => {
        this.meta = {
          apartments: res.data.apartments,
          customers: res.data.customers,
        }
        this.meta.apartments.unshift({
          id : "",
          name : "全て"
        })
      })
    },
  }
</script>
<style>
  .note p{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
</style>

